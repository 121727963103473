<template>
  <div class="login-page">
    <div class="sky">
      <div class="star" v-for="n in 50" :key="n" :style="getStarStyle(n)"></div>
      <div class="planet">
        <img src="@/assets/planet.png" alt="Planet" />
      </div>
      <div class="rocket">
        <img src="@/assets/rocket.png" alt="Rocket" />
      </div>
    </div>
    <div class="login-form">
      <h2>Welcome Back!</h2>
      <form @submit.prevent="handleLogin">
        <div class="form-field">
          <label for="username">Username</label>
          <input type="text" id="username" v-model="username" required />
        </div>
        <div class="form-field">
          <label for="password">Password</label>
          <input type="password" id="password" v-model="password" required />
        </div>
        <button type="submit" class="login-button">Login</button>
      </form>
      <p>Don't have an account?</p>
      <a>Unfortunately we only provide Teammember logins by now!</a>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const username = ref('');
const password = ref('');

const handleLogin = () => {
  // Hier kannst du deine Login-Logik implementieren
  console.log('Username:', username.value);
  console.log('Password:', password.value);
  alert('Login erfolgreich!');
  router.push('/');
};

// Funktion, um zufällige Positionen und Dauer für die Sterne zu generieren
const getStarStyle = (n) => {
  const top = Math.random() * 100;
  const left = Math.random() * 100;
  const duration = 2 + Math.random() * 3; // Zwischen 2s und 5s
  return {
    top: `${top}%`,
    left: `${left}%`,
    animationDuration: `${duration}s`,
  };
};
</script>

<style scoped>
.login-page {
  position: relative;
  height: 100vh;
  background: linear-gradient(to bottom, #020111 0%, #3a3a52 100%);
  overflow: hidden;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sky {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.star {
  position: absolute;
  width: 2px;
  height: 2px;
  background: #fff;
  border-radius: 50%;
  opacity: 0.8;
  animation: twinkle infinite ease-in-out alternate;
}

@keyframes twinkle {
  from { opacity: 0.5; }
  to { opacity: 1; }
}

.planet {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 200px;
}

.planet img {
  width: 100%;
}

.rocket {
  position: absolute;
  bottom: -12px;
  right: 10%;
  width: 150px;
  animation: fly 5s infinite ease-in-out;
}

.rocket img {
  width: 100%;
}

@keyframes fly {
  0% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
}

.login-form {
  width: 25%;
  position: relative;
  z-index: 10;
  background: rgba(0, 0, 0, 0.7);
  padding: 40px;
  border-radius: 10px;
  text-align: center;
}

.login-form h2 {
  margin-bottom: 20px;
}

.form-field {
  margin-bottom: 20px;
  text-align: left;
}

.form-field label {
  display: block;
  margin-bottom: 5px;
}

.form-field input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.login-button {
  width: 100%;
  padding: 10px;
  background: #ff6f61;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.login-button:hover {
  background: #ff3b30;
}

.login-form p {
  margin-top: 20px;
}

.login-form a {
  color: #ff6f61;
}

@media (max-width: 1200px) {
  .login-form {
    width: auto;
  }
}
</style>
