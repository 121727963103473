<template>
  <div class="homepage">
    <div class="header">
      <div class="titles">
        <img style="width: 100%" src="@/assets/gen.png" alt="logo" />
        <div class="text">
          <h1 class="title">Genempire.NET</h1>
          <p class="slogan">Build, grow, and thrive in your ultimate Minecraft empire!</p>
          <Button severity="secondary" label="Join now!" class="join-button" />
        </div>
      </div>

      <div class="scroll-down-indicator" style="zoom: 1.5">
        <i class="pi pi-angle-double-down"></i>
      </div>
    </div>

    <div class="info-section">
      <div class="card-wrapper" ref="infoCard1">
        <Card
          class="info-card"
          :class="{ 'in-view': isInView1 }"
          @click="redirectTo('apply')"
        >
          <template #title>
            <h3>Apply</h3>
          </template>
          <template #content>
            <p>Join our team and help create an unforgettable
              Minecraft experience for all players!</p>
            <Button severity="secondary" label="learn more" icon="pi pi-search" />
          </template>
        </Card>
      </div>

      <div class="card-wrapper" ref="infoCard2">
        <Card
          class="info-card"
          :class="{ 'in-view': isInView2 }"
          @click="redirectTo('beta-tester')"
        >
          <template #title>
            <h3>Join our Beta-Testers</h3>
          </template>
          <template #content>
            <p>Apply now to become a Beta Tester and help shape the
              future of our Minecraft server!</p>
            <Button severity="secondary" label="learn more" icon="pi pi-search" />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import Button from 'primevue/button';
import Card from 'primevue/card';
import 'primeicons/primeicons.css';

const router = useRouter();

const redirectTo = (path) => {
  router.push(`/${path}`);
};

const isInView1 = ref(false);
const isInView2 = ref(false);
const infoCard1 = ref(null);
const infoCard2 = ref(null);

const observerCallback = (entries) => {
  entries.forEach((entry) => {
    if (entry.target === infoCard1.value) {
      isInView1.value = entry.isIntersecting;
    }
    if (entry.target === infoCard2.value) {
      isInView2.value = entry.isIntersecting;
    }
  });
};

onMounted(async () => {
  await nextTick();

  const observer = new IntersectionObserver(observerCallback, {
    threshold: 0.1,
  });

  if (infoCard1.value instanceof HTMLElement) {
    observer.observe(infoCard1.value);
  }

  if (infoCard2.value instanceof HTMLElement) {
    observer.observe(infoCard2.value);
  }
});
</script>

<style scoped>
@font-face {
  font-family: 'rosemero';
  src: url('@/assets/fonts/ROSEMERO.ttf') format('truetype');
}

.homepage {
  background: linear-gradient(135deg, #2c3e50 0%, #bdc3c7 100%);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 100vh;
  text-align: center;
  z-index: 1;
  height: 100vh;
  animation: fadeIn 1s ease-out forwards;
}

.title {
  font-family: rosemero, sans-serif;
  font-size: 3rem;
  color: #fff;
  margin-bottom: 1rem;
  animation: slideDown 1s ease-out forwards;
}

.slogan {
  font-family: rosemero, sans-serif;
  font-size: 1.2rem;
  color: #ecf0f1;
  margin-bottom: 2rem;
  animation: fadeIn 1.5s ease-out forwards;
}

.join-button {
  animation: fadeIn 2s ease-out forwards;
}

.join-button:hover {
  background-color: #2980b9;
}

.scroll-down-indicator {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 2rem;
  color: #fff;
  opacity: 0.7;
  animation: bounce 2s infinite;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(10px);
  }
  60% {
    transform: translateY(5px);
  }
}

.info-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  padding: 2rem;
  gap: 2rem;
  flex-wrap: wrap;
  height: 100vh;
}

.info-card {
  flex: 1 1 300px;
  cursor: pointer;
  max-width: 400px;
  height: auto;
  opacity: 0;
  transform: translateY(20px);
  background-color: #fff;
  color: #2c3e50;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  box-sizing: border-box;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.info-card.in-view {
  opacity: 1;
  transform: translateY(0);
  animation: cardFadeIn 0.5s ease-out forwards;
}

@keyframes cardFadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.info-card h3 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.info-card p {
  color: #7f8c8d;
}

.info-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.info-card:nth-child(odd) {
  border-top: 4px solid #e74c3c;
}

.info-card:nth-child(even) {
  border-top: 4px solid #8e44ad;
}

@media (max-width: 768px) {
  .title {
    font-size: 2.5rem;
  }

  .titles {
    display: grid;
    grid-template-rows: minmax(0, .5fr) auto;
    grid-template-columns: 1fr !important;

    img {
      padding-top: 15%;
      width: 50% !important;
    }
  }

  .slogan {
    font-size: 1rem;
  }

  .join-button {
    font-size: 0.9rem;
    padding: 0.6rem 1.2rem;
  }

  .info-section {
    flex-direction: column;
    gap: 1rem;
  }
}

.titles {
  height: 95%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.18);
  height: auto;
  box-sizing: border-box;
}

@media (max-width: 400px) {
  .titles {
    display: grid;
    grid-template-rows: minmax(0, .5fr) auto;
    grid-template-columns: 1fr !important;

    img {
      padding-top: 50% !important;
      width: 100% !important;
    }
  }
}
</style>
