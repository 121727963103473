<template>
  <div class="not-found-page">
    <div class="sky">
      <div class="star" v-for="n in 50" :key="n"></div>
      <div class="moon"></div>
      <div class="astronaut">
        <img src="@/assets/astronaut.png" alt="Lost Astronaut" />
      </div>
    </div>
    <div class="message">
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <p>Looks like you're lost in space.</p>
      <router-link to="/" class="home-button">Take Me Home</router-link>
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
.not-found-page {
  position: relative;
  height: 100vh;
  background: linear-gradient(to bottom, #020111 0%, #3a3a52 100%);
  overflow: hidden;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #fff;
}

.sky {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.star {
  position: absolute;
  width: 2px;
  height: 2px;
  background: #fff;
  border-radius: 50%;
  top: calc(100% * var(--random-top));
  left: calc(100% * var(--random-left));
  opacity: 0.8;
  animation: twinkle var(--random-duration) infinite ease-in-out alternate;
}

.star::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: inherit;
  border-radius: inherit;
}

@for $i from 1 through 50 {
  .star:nth-of-type(#{$i}) {
    --random-top: #{random()};
    --random-left: #{random()};
    --random-duration: #{random(2, 5)}s;
  }
}

@function random($min: 0, $max: 1) {
$random-value: random() / 1;
  @return $min + ($random-value * ($max - $min));
}

@keyframes twinkle {
  from { opacity: 0.5; }
  to { opacity: 1; }
}

.moon {
  position: absolute;
  top: 20%;
  left: 70%;
  width: 150px;
  height: 150px;
  background: #fff;
  border-radius: 50%;
  box-shadow: -15px 0 0 0 #f0f0f0;
}

.astronaut {
  position: absolute;
  bottom: 0;
  left: 10%;
  width: 200px;
  animation: float 4s infinite ease-in-out;
}

.astronaut img {
  width: 100%;
}

@keyframes float {
  0% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
  100% { transform: translateY(0); }
}

.message {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  z-index: 10;
}

.message h1 {
  font-size: 6rem;
  margin: 0;
}

.message h2 {
  font-size: 2rem;
  margin: 10px 0;
}

.message p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.home-button {
  display: inline-block;
  padding: 10px 20px;
  background: #ff6f61;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.home-button:hover {
  background: #ff3b30;
}
</style>
